.stops {
    display: flex;
    align-items: center;
    animation: scrollText 15s linear infinite;
}

@keyframes scrollText {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(-200%, 0);
    }
}