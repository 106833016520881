.arrival {
    background-color: #017142ab;
    display: grid;
    grid-template-columns: 1fr 6fr 6fr 12fr 1fr;
    grid-template-rows: 1fr 0.5fr 1fr;
    color: white;
    font-size: 1.5rem;

    @media screen and (max-width: 850px) {
      font-size: 1.2rem;
    }

    @media screen and (max-width: 650px) {
        font-size: 1rem;
    }

    // For differencing each line
    &--light {
        background-color: #00A253ab;
    }

    &__train-type {
        grid-column-start: 2;
        grid-column-end: span 1;
        grid-row-start: 1;
        grid-row-end: span 1;
        font-size: 0.8rem;
    }

    &__train-number {
        grid-column-start: 2;
        grid-column-end: span 1;
        grid-row-start: 2;
        grid-row-end: span 1;
        font-size: 0.8rem;
    }

    &__time {
        grid-column-start: 3;
        grid-column-end: span 1;
        grid-row-start: 1;
        grid-row-end: span 1;
        color: #E1E929;
        font-weight: 700;
        text-align: center;
    }

    &__delay {
        grid-column-start: 3;
        grid-column-end: span 1;
        grid-row-start: 2;
        grid-row-end: span 1;
        text-align: center;
        font-size: 1rem;
    }

    &__origin {
        grid-column-start: 4;
        grid-column-end: span 1;
        grid-row-start: 1;
        grid-row-end: span 2;
        font-weight: 700;
    }

    &__stops {
        white-space: nowrap;
        grid-column-start: 2;
        grid-column-end: span 3;
        grid-row-start: 3;
        grid-row-end: span 1;
        overflow: hidden;

        li {
            margin-right: 0.5rem;
            font-size: 1.2rem;

            @media screen and (max-width: 750px) {
                font-size: 1rem;
            }

            img {
                width: 10px;
                height: 10px;
                border-radius: 50%;
                margin-left: 0.5rem;
            }
        }
    }
}