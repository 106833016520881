@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');

body {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  background-color: #0C131F;
}

html,
body,
nav,
header,
footer,
div,
span,
h1,
h2,
h3,
p,
a,
img,
ul,
li,
table,
form,
label,
input,
textarea,
select,
button {
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
}

li {
  list-style: none;
}